import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { Analytics } from '../../services/analytics';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { toError } from '../../utils/errors';
import { CtaClickHandler } from '../Plan/viewer.controller';
import model from './model';

const getDemoPlan = (t: EditorScriptFlowAPI['translations']['t']): PublicPlan => ({
  name: t('blocks.demo-plan.name'),
  description: t('blocks.demo-plan.description'),
  pricing: {
    price: {
      value: '15',
      currency: 'USD',
    },
    subscription: {
      cycleCount: 3,
      cycleDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
  },
  perks: {
    values: [t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk'), t('blocks.demo-plan.perk')],
  },
});

export default model.createController(({ $w, $widget, flowAPI }) => {
  let autoLoadPlan: boolean = true;

  const getDemoPlanIfEditor = () => {
    return flowAPI.environment.isEditor ? getDemoPlan(flowAPI.translations.t) : null;
  };

  const fetchPlan = async (planId?: string): Promise<PublicPlan | null> => {
    if (!planId) {
      return getDemoPlanIfEditor();
    }
    // TODO: Use warmup data
    const response = await flowAPI.httpClient.request(listPublicPlans({ planIds: [planId] }));
    return response.data.plans?.[0] ?? getDemoPlanIfEditor();
  };

  const setPlan = async (planId: string) => {
    flowAPI.fedops.interactionStarted(SinglePlanInteractions.SetPlanData);
    if (!flowAPI.environment.isEditor && !flowAPI.environment.isSSR) {
      showSpinner();
    }

    try {
      const plan = await fetchPlan($widget.props.planId);
      if (!plan) {
        showEmptyState();
      } else {
        await $w(`#${SinglePlanWidgetRole.PlanWidget}`).setPlan(plan);
        showLoadedState();
        const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
        analytics.addProductImpression([plan]);
      }
      flowAPI.fedops.interactionEnded(SinglePlanInteractions.SetPlanData);
    } catch (e) {
      flowAPI.errorMonitor.captureException(toError(e));
      showEmptyState();
    }
  };

  const showSpinner = () => {
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateSpinner);
  };

  const showEmptyState = () => {
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateEmpty);
  };

  const showLoadedState = () => {
    return $w(`#${SinglePlanWidgetRole.MultiStateBox}`).changeState(SinglePlanWidgetRole.StateLoaded);
  };

  $widget.onPropsChanged(async (oldProps, newProps) => {
    const plan = await fetchPlan(newProps.planId);
    $w(`#${SinglePlanWidgetRole.PlanWidget}`).setPlan(plan);
  });

  return {
    pageReady: async () => {
      if (!autoLoadPlan) {
        return;
      }
      // Viewer controller can be executed without widget being rendered
      // This happens in editor, when page has an OOI widget, but not blocks widget
      const isWidgetRendered = $w(`#${SinglePlanWidgetRole.PlanWidget}`).setPlan !== undefined;
      if (isWidgetRendered) {
        return setPlan($widget.props.planId);
      } else {
        console.error('Single Plan Widget does not exist in page');
      }
    },
    exports: {
      onSelect: (cb: CtaClickHandler) => {
        $w(`#${SinglePlanWidgetRole.PlanWidget}`).onSelect(cb);
      },
      setPlan: (planId: string) => {
        autoLoadPlan = false;
        setPlan(planId);
      },
    },
  };
});
