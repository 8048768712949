import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick } from '@wix/bi-logger-membership/v2';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { getPricingPlansPublicAPI, PricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { getSetupFeeAmount, hasSetupFee } from '@wix/pricing-plans-utils';
import { getCoverImage, CoverImage } from '@wix/pricing-plans-utils/client-data';
import { TFunction } from '@wix/yoshi-flow-editor';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { FormattedPriceData, getFormattedPriceData } from '../../hooks';
import { Analytics } from '../../services/analytics';
import { SinglePlanInteractions } from '../../types/SinglePlanFedops';
import { getFreeTrialDaysLabel, getPeriodLabel, getPlanDuration, resolveLocale } from '../../utils';
import { toError } from '../../utils/errors';
import { getFormattedAdditionalFees } from '../../utils/get-formatted-additional-fees';
import { PricingWidgetParams } from '../Pricing/viewer.controller';
import model from './model';

export type CtaClickHandler = (plan: PublicPlan) => void;

export default model.createController(({ $w, flowAPI }) => {
  let externalOnSelectHandler: CtaClickHandler | undefined;
  let api: PricingPlansPublicAPI;

  const updatePlanData = async (plan: PublicPlan) => {
    const t = flowAPI.translations.t as TFunction;
    const coverImage = getCoverImage(plan.clientData);
    if (coverImage) {
      $w(`#${SinglePlanWidgetRole.Image}`).src = getImageSrc(coverImage);
    }
    $w(`#${SinglePlanWidgetRole.Image}`).expand();

    setDescription(plan);

    const freeTrial = getFreeTrialDaysLabel(plan, t);
    const duration = getPlanDuration(plan, t);
    const planDuration = freeTrial ? `${duration}\n${freeTrial}` : duration;

    $w(`#${SinglePlanWidgetRole.PlanName}`).text = plan.name!;
    $w(`#${SinglePlanWidgetRole.PlanDuration}`).text = planDuration;

    const { price, currency, fullPrice } = getPriceData(plan);
    const paymentFrequency = getPeriodLabel(plan.pricing?.subscription, t) ?? '';
    const setupFee = getSetupFee(plan);
    $w(`#${SinglePlanWidgetRole.PricingWidget}`).setPricing({
      price,
      currency,
      paymentFrequency,
      setupFee,
    } satisfies PricingWidgetParams);
    setA11yAttributes(plan, fullPrice);

    // setPerks always needs to be called, to make benefits repeater dynamic
    // viewer currently doesn't support static repeater inside dynamic repeater serialization https://wix.slack.com/archives/C9N00LLE6/p1689155061640139
    $w(`#${SinglePlanWidgetRole.PerksWidget}`).setPerks(plan.perks?.values ?? []);

    if (plan.perks?.values?.length) {
      $w(`#${SinglePlanWidgetRole.ContentDividerContainer}`).show();
      $w(`#${SinglePlanWidgetRole.PerksWidget}`).expand();
    } else {
      $w(`#${SinglePlanWidgetRole.ContentDividerContainer}`).hide();
      $w(`#${SinglePlanWidgetRole.PerksWidget}`).collapse();
    }
  };

  const setDescription = (plan: PublicPlan) => {
    if (plan.description) {
      $w(`#${SinglePlanWidgetRole.Description}`).text = plan.description;
      $w(`#${SinglePlanWidgetRole.Description}`).expand();
    } else {
      $w(`#${SinglePlanWidgetRole.Description}`).text = '';
      $w(`#${SinglePlanWidgetRole.Description}`).collapse();
    }
  };

  const setA11yAttributes = (plan: PublicPlan, formattedPrice: string) => {
    // @ts-expect-error
    $w(`#${SinglePlanWidgetRole.PlanContainer}`).accessibility.ariaAttributes.label = plan.name;

    if ($w(`#${SinglePlanWidgetRole.RibbonWidget}`).isVisible) {
      const ribbonTextElement = $w(`#${SinglePlanWidgetRole.RibbonWidget}`).getTextElement();
      // @ts-expect-error
      $w(`#${SinglePlanWidgetRole.PlanContainer}`).accessibility.ariaAttributes.describedBy = ribbonTextElement;
    }

    if ($w(`#${SinglePlanWidgetRole.PricingWidget}`).isVisible) {
      // @ts-expect-error
      $w(`#${SinglePlanWidgetRole.PlanName}`).accessibility.screenReader.suffix = formattedPrice;
    }
  };

  const getPriceData = (plan: PublicPlan): FormattedPriceData => {
    const { value, currency } = plan.pricing?.price!;
    return getFormattedPriceData({
      createCurrencyFormatter: flowAPI.getCurrencyFormatter,
      locale: resolveLocale(flowAPI.controllerConfig.wixCodeApi),
      value,
      currency,
    });
  };

  const getSetupFee = (plan: PublicPlan): string => {
    return getFormattedAdditionalFees({
      plan,
      locale: resolveLocale(flowAPI.controllerConfig.wixCodeApi),
      createCurrencyFormatter: flowAPI.getCurrencyFormatter,
      enabled: flowAPI.experiments.enabled(TPA_EXPERIMENTS.SETUP_FEE),
      hideTrailingFractionZeroes: true,
    })
      .map(({ amount, name }) => `+${amount} ${name}`)
      .join('\n');
  };

  const registerCtaHandler = async (plan: PublicPlan) => {
    $w(`#${SinglePlanWidgetRole.Button}`).onClick(() => {
      const analytics = new Analytics(flowAPI.controllerConfig.wixCodeApi.window);
      analytics.clickProduct(plan);
      // TODO: Should external handler have different BI data?
      flowAPI?.bi?.report(
        planPurchaseClick({
          planGuid: plan.id ?? '',
          widgetType: 'single',
          setupFeeAmount: hasSetupFee(plan) ? Math.round(Number(getSetupFeeAmount(plan)) * 100) : undefined,
        }),
      );

      if (typeof externalOnSelectHandler === 'function') {
        externalOnSelectHandler(plan);
      } else {
        api.navigateToCheckout({ planId: plan.id! });
      }
    });
  };

  return {
    pageReady: async () => {
      if (!flowAPI.environment.isEditor) {
        $w(`#${SinglePlanWidgetRole.Image}`).collapse();
      }

      try {
        flowAPI.fedops.interactionStarted(SinglePlanInteractions.GetPricingPlansApi);
        api = await getPricingPlansPublicAPI(flowAPI.controllerConfig.wixCodeApi);
        flowAPI.fedops.interactionEnded(SinglePlanInteractions.GetPricingPlansApi);
      } catch (e) {
        flowAPI.errorMonitor.captureException(toError(e));
      }
    },
    exports: {
      setPlan: async (plan: PublicPlan) => {
        updatePlanData(plan);
        registerCtaHandler(plan);
      },
      onSelect: (cb: CtaClickHandler) => {
        externalOnSelectHandler = cb;
      },
    },
  };
});

function getImageSrc({ uri, width, height }: CoverImage): string {
  return `wix:image://v1/${uri}/${uri}#originWidth=${width}&originHeight=${height}`;
}
